html, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  line-height: 1.5;
  -webkit-font-smoothing: subpixel-antialiased;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

@media (min-width: 769px) {
  html {
    overflow: hidden;
    height: 100%;
  }
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: 'Roboto Condensed', sans-serif;
}

body {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  line-height: 1.5;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  font-size: 15px;
  color: #333;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  transition: width 0.2s;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.2);
  transition: all 0.2s;
}

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(155, 154, 154, 0.4);
  }

.application {
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.bg-primary {
  background-color: #8b0305 !important;
}

.bg-secondary {
  background-color: rgba(0, 0, 0, 0.54);
}

.bg-secondary-light {
  background-color: rgba(0, 0, 0, 0.04);
}

.btn-primary {
  background-color: #8b0305 !important;
  border: 1px solid #8b0305 !important;
}

.text-primary {
  background-color: #8b0305 !important;
}

button:focus {
  outline: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.MuiAlert-icon {
  align-items: center;
  justify-content: center;
}

.primary-header .dx-row.dx-header-row {
  background-color: #8b0305;
  color: #fff;
}

.dx-texteditor.dx-editor-outlined {
  background-color: #dedede;
  box-shadow: none;
}

.dx-textbox {
  line-height: 0;
  font-size: 14px;
}

.dx-textbox input {
  padding: 10px 10px 9px !important;
  font-size: 14px;
}

.dx-state-hover::not(.dx.state.focused) {
  box-shadow: none !important;
}

.dx-button-text {
  letter-spacing: 2px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
}
